<template>
  <!-- profile info  -->
  <section id="profile-info">
    <b-row>
      <b-col
        lg="3"
        cols="12"
        order="2"
        order-lg="1"
      >
        <profile-about :master-id="userData.instansi_id" />
        <profile-side-menu />
      </b-col>

      <!-- post -->
      <b-col
        lg="9"
        cols="12"
        order="1"
        order-lg="2"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mt-2"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row>
            <!-- <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_belanja_id"
                  v-model="master_jenis_belanja_id"
                  required
                  name="master_jenis_belanja_id"
                  :options="referenceData.ref_jenis_belanja"
                  placeholder="Jenis Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col> -->
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_jenis_pengadaan_id"
                  v-model="master_jenis_pengadaan_id"
                  required
                  name="master_jenis_pengadaan_id"
                  :options="referenceData.ref_jenis_pengadaan"
                  placeholder="Kriteria Belanja"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group>
                <v-select
                  id="vi-master_sumber_dana_id"
                  v-model="master_sumber_dana_id"
                  name="master_sumber_dana_id"
                  :options="referenceData.ref_sumber_dana"
                  placeholder="Sumber Dana"
                  label="nama"
                  @input="doFilter()"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              perPage:serverParams.length
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'anggaran'">
                {{ Number(props.row.anggaran).toLocaleString() }}
              </span>
              <span v-else-if="props.column.field === 'nama'">
                <b-badge
                  variant="primary"
                  class="text-wrap"
                >
                  {{ props.row.domain }}
                </b-badge>
                <span class="d-block">
                  {{ props.row.nama }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'last_update'">
                {{ props.row.last_update | formatDate }}
              </span>
              <span v-else-if="props.column.field === 'wait_time'">
                <!-- <template v-if="props.row.status === 'Menunggu Tanggapan IPPD'">
                  {{ props.row.wait_time | formatDate }}
                </template> -->
                {{ props.row.wait_time | formatDate }}
              </span>
              <!-- Lihat Rekomendasi Direktur -->
              <span v-else-if="props.column.field == 'rekomendasi'">
                <b-badge
                  :variant="statusVariant(props.row.rekomendasi).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.rekomendasi).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field == 'status'">
                <b-badge
                  :variant="statusVariant(props.row.status).class"
                  class="text-wrap"
                  style="max-width:120px"
                >
                  {{ statusVariant(props.row.status).title }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field == 'document'">
                <b-button
                  v-if="props.row.document !== ''"
                  variant="success"
                  size="sm"
                  class="mb-25 mr-25 mb-25"
                  @click="openKomponen(props.row.document)"
                >
                  <feather-icon
                    icon="BookOpenIcon"
                    size="14"
                  /></b-button>
                <b-link
                  v-if="props.row.document !== ''"
                  :href="getDoc(props.row.document)"
                  class="btn btn-sm btn-info mb-25 mr-25 mb-25"
                  target="_blank"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="14"
                  /></b-link>
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field == 'action'">
                <b-button
                  v-if="props.row.status !== 'Selesai Konfirmasi' && userData.group_id === 100"
                  v-b-tooltip.hover
                  variant="info"
                  size="sm"
                  class="m-25"
                  title="Konfirmasi Hasil"
                  @click="openConfirm(props.row)"
                >
                  <feather-icon
                    icon="SendIcon"
                    size="12"
                  />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  variant="primary"
                  size="sm"
                  class="m-25"
                  title="Detail Rekomendasi"
                  @click="openRekomendasi(props.row)"
                >
                  <feather-icon
                    icon="ListIcon"
                    size="12"
                  />
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.length"
                    :options="['10','20','50']"
                    class="mx-1"
                    @change="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    v-model="selectPage"
                    :total-rows="props.total"
                    :per-page="serverParams.length"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <!-- post -->
    </b-row>

    <b-modal
      id="modal-komponen"
      ref="modal-komponen"
      hide-footer
      centered
      scrollable
      size="lg"
    >
      <b-card-text>
        <!-- search input -->
        <vue-pdf-app
          :pdf="fileDoc"
          style="height: 90vh;"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-rekomendasi"
      ref="modal-rekomendasi"
      hide-footer
      centered
      size="lg"
      title="Hasil Pembahasan Rekomendasi Sementara"
    >
      <b-card-text>
        <summary-rekomendasi
          :keterangan="listRekomendasi.keterangan"
          :rekap-lanjut="listRekomendasi.continue"
          :rekap-tidak="listRekomendasi.postpone"
        />
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning"
      ref="modal-warning"
      ok-only
      ok-variant="info"
      ok-title="Ya"
      modal-class="modal-info"
      centered
      title="Konfirmasi Hasil Pembahasan Rekomendasi Sementara"
      @ok="updateSave()"
    >
      <b-card-text>
        Memberikan konfirmasi bahwa hasil pembahasan rekomendasi sementara telah sesuai?
      </b-card-text>
    </b-modal>

    <b-modal
      id="modal-warning2"
      ref="modal-warning2"
      hide-footer
      modal-class="modal-success"
      centered
      title="Sukses"
    >
      <b-card-text>
        Konfirmasi Berhasil Disimpan.
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import moment from 'moment-business-time'
import vSelect from 'vue-select'
import {
  BBadge, BButton, BModal, BRow, BCol, BCard, BPagination, BCardText, BLink,
  BFormGroup, BFormInput, BFormSelect, VBTooltip, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import VuePdfApp from 'vue-pdf-app'

import ProfileAbout from './module/PengajuanAbout.vue'
import ProfileSideMenu from './module/PengajuanSideMenu.vue'

import SummaryRekomendasi from '../rekomendasi/SummaryRekomendasi.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BModal,
    BBadge,
    BButton,
    BLink,
    BCardText,
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,

    ProfileAbout,
    ProfileSideMenu,
    SummaryRekomendasi,
    vSelect,
    VuePdfApp,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 110 || role < 99) {
      next('/')
    } else {
      next()
    }
  },
  data() {
    return {
      year_budget: localStorage.getItem('tahunAnggaran'),
      fileDoc: '',
      alertText: '',
      referenceData: {
        ref_instansi: [],
        ref_jenis_belanja: [],
        ref_jenis_pengadaan: [],
        ref_sumber_dana: [],
      },
      userData: getUserData(),
      isLoading: false,
      nama_kegiatan: '',
      nama_app_infra: '',
      columns: [],
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        sort_by: [
          {
            column: 'last_update',
            asc_desc: 'desc',
          },
        ],
        start: 0,
        length: 10,
      },
      rows: [],
      searchTerm: '',
      errorStat: false,
      errorMsg: '',
      listRekomendasi: {},
      master_data_instansi: '',
      master_jenis_belanja_id: '',
      master_jenis_pengadaan_id: '',
      master_sumber_dana_id: '',
      saveData: {},
    }
  },
  created() {
    this.setupTanggal()
  },
  mounted() {
    this.getReference()
    if (localStorage.getItem('master_jenis_belanja_id')) {
      this.master_jenis_belanja_id = JSON.parse(localStorage.getItem('master_jenis_belanja_id'))
    }
    if (localStorage.getItem('master_jenis_pengadaan_id')) {
      this.master_jenis_pengadaan_id = JSON.parse(localStorage.getItem('master_jenis_pengadaan_id'))
    }
    if (localStorage.getItem('master_sumber_dana_id')) {
      this.master_sumber_dana_id = JSON.parse(localStorage.getItem('master_sumber_dana_id'))
    }
    if (localStorage.getItem('searchTerm')) {
      this.searchTerm = localStorage.getItem('searchTerm')
    }
    if (localStorage.getItem('nama_kegiatan')) {
      this.nama_kegiatan = localStorage.getItem('nama_kegiatan')
    }
    if (localStorage.getItem('nama_app_infra')) {
      this.nama_app_infra = localStorage.getItem('nama_app_infra')
    }
    this.setColumns()
    this.doFilter()
    this.getReference()
  },
  methods: {
    openKomponen(fileName) {
      this.fileDoc = `${process.env.VUE_APP_API_URL}${fileName}?token=${localStorage.getItem('userToken')}`
      this.$refs['modal-komponen'].show()
    },
    getDoc(data) {
      return `${process.env.VUE_APP_API_URL}${data}?token=${localStorage.getItem('userToken')}`
    },
    setupTanggal() {
      let holidays = []
      if (localStorage.getItem('holidays')) {
        holidays = JSON.parse(localStorage.getItem('holidays'))
      } else {
        this.$http.get('/tanggalan/holidays')
          .then(res => {
            const liburAll = res.data.data
            const libur = []

            Object.keys(liburAll).map(data => {
              libur.push(data)
              return data
            })
            holidays = libur
            localStorage.setItem('holidays', JSON.stringify(libur))
          })
      }
      moment.updateLocale('en', {
        holidays,
      })
    },
    tableSearch() {
      localStorage.setItem('searchTerm', this.searchTerm)
      this.updateParams({ keyword: this.searchTerm })
      this.doFilter()
    },
    searchReset() {
      this.searchTerm = ''
      localStorage.removeItem('searchTerm')
      this.updateParams({ keyword: '' })
      this.doFilter()
    },
    setColumns() {
      const columns = [
        {
          thClass: 'text-center',
          label: 'Nama Kegiatan',
          field: 'nama_kegiatan',
          width: '175px',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Kegiatan',
            filterValue: (this.nama_kegiatan !== '') ? this.nama_kegiatan : null,
            // trigger: 'enter',
          },
        },
        {
          thClass: 'text-center',
          label: 'Item Belanja',
          field: 'nama',
          width: '175px',
          filterOptions: {
            enabled: true,
            placeholder: 'Item Belanja',
            filterValue: (this.nama_app_infra !== '') ? this.nama_app_infra : null,
            // trigger: 'enter',
          },
        },
        {
          thClass: 'text-center',
          label: 'Total Anggaran',
          field: 'anggaran',
          type: 'number',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Perubahan Terakhir',
          field: 'last_update',
        },
        {
          thClass: 'text-center',
          label: 'Hasil Pembahasan Rekomendasi',
          field: 'rekomendasi',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Status',
          field: 'status',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Batas Waktu',
          field: 'wait_time',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Dokumen',
          field: 'document',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ]
      this.columns = columns
    },
    getReference() {
      this.$http.get('/clearance/core-data/references', {
        params: {
          token: localStorage.getItem('userToken'),
          tahun_anggaran: this.year_budget,
        },
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.referenceData = res.data.data
          }
        })
    },
    statusVariant(status) {
      const statusColor = {
        Dilanjutkan: {
          title: 'Dilanjutkan',
          class: 'success',
        },
        'Tidak Dilanjutkan': {
          title: 'Tidak Dilanjutkan',
          class: 'danger',
        },
        'Menunggu Konfirmasi IPPD': {
          title: 'Menunggu Konfirmasi IPPD',
          class: 'light-info',
        },
        'Selesai Konfirmasi': {
          title: 'Selesai Konfirmasi',
          class: 'light-success',
        },
      }
      if (statusColor[status]) {
        return statusColor[status]
      }
      return {
        title: status,
        class: 'light-info',
      }
    },
    globalFilter() {
      const params = []
      if (this.master_jenis_belanja_id) {
        params.push({
          column: 'jenis_belanja_id',
          value: String(this.master_jenis_belanja_id.jenis_belanja_id),
        })
        localStorage.setItem('master_jenis_belanja_id', JSON.stringify(this.master_jenis_belanja_id))
      } else {
        localStorage.removeItem('master_jenis_belanja_id')
      }
      if (this.master_jenis_pengadaan_id) {
        params.push({
          column: 'jenis_pengadaan_id',
          value: String(this.master_jenis_pengadaan_id.jenis_pengadaan_id),
        })
        localStorage.setItem('master_jenis_pengadaan_id', JSON.stringify(this.master_jenis_pengadaan_id))
      } else {
        localStorage.removeItem('master_jenis_pengadaan_id')
      }
      if (this.master_sumber_dana_id) {
        params.push({
          column: 'sumber_dana_id',
          value: String(this.master_sumber_dana_id.sumber_dana_id),
        })
        localStorage.setItem('master_sumber_dana_id', JSON.stringify(this.master_sumber_dana_id))
      } else {
        localStorage.removeItem('master_sumber_dana_id')
      }
      return params
    },
    doFilter() {
      const params = this.globalFilter()
      if (this.nama_kegiatan !== '') {
        params.push({
          column: 'nama_kegiatan',
          value: this.nama_kegiatan,
        })
        localStorage.setItem('nama_kegiatan', this.nama_kegiatan)
      } else {
        localStorage.removeItem('nama_kegiatan')
      }
      if (this.nama_app_infra !== '') {
        params.push({
          column: 'nama_app_infra',
          value: this.nama_app_infra,
        })
        localStorage.setItem('nama_app_infra', this.nama_app_infra)
      } else {
        localStorage.removeItem('nama_app_infra')
      }
      if (this.searchTerm !== '') {
        this.updateParams({ keyword: this.searchTerm })
        localStorage.setItem('searchTerm', this.searchTerm)
      } else {
        this.updateParams({ keyword: '' })
        localStorage.removeItem('searchTerm')
      }
      this.updateParams({
        filters: params,
      })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.selectPage = params.currentPage
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.doFilter()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.doFilter()
    },
    onSortChange(params) {
      params.map(param => {
        let columName = param.field
        if (param.field === 'nama') {
          columName = 'nama_app_infra'
        }
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: columName,
          }],
        })
        return true
      })
      this.doFilter()
    },
    onColumnFilter(params) {
      const filterz = this.globalFilter()
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        let columName = key

        if (key === 'nama') {
          columName = 'nama_app_infra'
        }

        if (valuez !== '') {
          const data = {
            column: columName,
            value: valuez,
          }
          filterz.push(data)
          localStorage.setItem(columName, String(valuez))
        } else {
          localStorage.removeItem(columName)
        }
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      this.$http.post(`/recommendation/force-to-accept-list?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            res.data.data.data.map(result => {
              const rekolist = result
              const lastUpdate = moment(rekolist.last_update).utc()
              rekolist.wait_time = lastUpdate.addWorkingTime(5, 'days').toLocaleString()

              this.rows.push(rekolist)
              return true
            })
            this.totalRecords = res.data.data.total_rows
          } else {
            this.totalRecords = 0
          }
        })
    },
    openRekomendasi(appData) {
      const params = {
        token: localStorage.getItem('userToken'),
        data_utama_id: appData.data_utama_id,
      }
      if (appData.domain === 'APLIKASI') {
        params.aplikasi_id = appData.aplikasi_id
        params.type = 'app'
      } else if (appData.domain === 'INFRASTRUKTUR') {
        params.infrastruktur_id = appData.infrastruktur_id
        params.type = 'infra'
      }
      if (appData.aplikasi_id) {
        params.type = 'app'
        params.aplikasi_id = appData.aplikasi_id
      } else if (appData.infrastruktur_id) {
        params.type = 'infra'
        params.infrastruktur_id = appData.infrastruktur_id
      } else if (appData.data_informasi_id) {
        params.type = 'data'
        params.data_informasi_id = appData.data_informasi_id
      } else if (appData.non_teknis_id) {
        params.type = 'non-teknis'
        params.non_teknis_id = appData.non_teknis_id
      }
      this.$http.get('/recommendation/get-rekomendasi-sementara', {
        params,
      })
        .then(res => {
          if (res.data.status === 'success') {
            this.listRekomendasi = res.data.data
            this.$refs['modal-rekomendasi'].show()
          }
        })
    },
    openConfirm(appData) {
      this.saveData = appData
      this.$refs['modal-warning'].show()
    },
    updateSave() {
      const verif = {
        data_utama_id: this.saveData.data_utama_id,
        domain: this.saveData.domain,
        pk_id: this.saveData.pk_id,
      }
      if (this.saveData.domain === 'APLIKASI') {
        verif.konfirmasi_id = this.saveData.aplikasi_id
      } else if (this.saveData.domain === 'INFRASTRUKTUR') {
        verif.konfirmasi_id = this.saveData.infrastruktur_id
      }
      if (this.saveData.aplikasi_id) {
        verif.domain = 'APLIKASI'
        verif.konfirmasi_id = this.saveData.aplikasi_id
      } else if (this.saveData.infrastruktur_id) {
        verif.domain = 'INFRASTRUKTUR'
        verif.konfirmasi_id = this.saveData.infrastruktur_id
      } else if (this.saveData.data_informasi_id) {
        verif.domain = 'DATA'
        verif.konfirmasi_id = this.saveData.data_informasi_id
      } else if (this.saveData.non_teknis_id) {
        verif.domain = 'NON-TEKNIS'
        verif.konfirmasi_id = this.saveData.non_teknis_id
      }
      this.$http.post(`/recommendation/force-to-accept?token=${localStorage.getItem('userToken')}`, verif)
        .then(res => {
          if (res.data) {
            this.dataSaved = true
            this.$refs['modal-warning2'].show()
            this.$refs['modal-warning'].hide()
            this.doFilter()

            setTimeout(() => {
              this.dataSaved = false
            }, 2000)
          }
          return res.data
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
